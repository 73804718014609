import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Row,
    Col
} from "reactstrap";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import { AvForm } from 'availity-reactstrap-validation';
import Constants from "../../core/Constants";
import SelectRow from "../../components/Select/SelectRow";

export default class ArticleAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        const html = '';
        const contentBlock = htmlToDraft(html);

        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.state = {
            isLoading: false,
            name:"",
            description:"",
            _id:"",
            fullpicture:"",
            picture:"",
            loading : false,
            editorState : editorState,
            articlecategory:[],
            categorylist:[],
        };



        this.pathname = "article";
        this.engine = new RequestEngine();
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    componentDidMount() {


        const edit = this.props.location.data


        if (edit){

            const html = edit.description;
            const contentBlock = htmlToDraft(html);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                ...edit,
                editorState:editorState,
                fullpicture:edit.picture.length>1 ? Constants.serverlink+"/upload/"+edit.picture:""
            });
        }

        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("articlecategory");
        if(responseuni){
            const data = responseuni.data.data;
            let categorylist = []
            data.map(p =>{
                    categorylist.push( { value: p._id, label: p.name})
                }
            )
            this.setState({
                categorylist: categorylist
            });
        }


    }


    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        const {  _id,name,picture,editorState,articlecategory }= this.state;


        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const formData = new FormData();
        formData.append('picture',picture)
        formData.append('_id',_id)
        formData.append('name',name)

        let categoryid = []
        articlecategory.map(i=>{
            if(i.value){
                categoryid.push(i.value)
            }else{
                categoryid.push(i)
            }
        })
        formData.append('articlecategory',categoryid)
        formData.append('description',description)

        const response = await this.engine.saveItemData(this.pathname,formData)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };

    onChangePic(e) {
        this.setState({picture:e.target.files[0]})
    }
    render() {
        // taking all the states
        const {loading,isLoading, editorState,  name,categorylist,articlecategory }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Title" name="name" data={name}   changeInput={this.changeInput.bind(this)} />
                                        <SelectRow label="category" isMulti={true}  name="category"   data={categorylist} defaultValue={articlecategory && articlecategory.map((item) => {
                                            return categorylist.find(v => v.value === item.value || v.value === item)
                                        })} changeInput={data =>
                                            this.setState({ articlecategory: data })
                                        }  />
                                        <Row>
                                            <Label sm="2">Description</Label>
                                            <Col sm="7" >
                                                <FormGroup   >
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="label-on-right" tag="label" sm="3">
                                                <code>required</code>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Image</Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select Image" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>







                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


