import React from "react";
import _ from "lodash";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import SelectRow from "../../components/Select/SelectRow";
import FormRow from "../../components/Row/FormRow";
import {AvForm} from "availity-reactstrap-validation";
import {Utilites} from "../../core/Utilites";

class CartAban extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.pathname = "cart";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }



    async handleDelete(valueid) {

        if(window.confirm("are you sure ?")){
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem(this.pathname,valueid)
            if(result){
                this.handlePage();
            }
            this.props.loadingAction(false)
        }


    }


    handleView(index,dataid) {
        const data = this.state.dataSource[index].data


        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/view/'+dataid,
            data
        });

    }

    async handleToggle(valueid,status) {
        console.log(valueid);
        this.props.loadingAction(true)
        const result = await this.engine.getItem(this.pathname,"/toggle/"+valueid+"/"+status)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)
    }
    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>)
    }



    async handleViewBenExport() {
        if(this.state.deliverydate.length<2){
            Utilites.showErrorMessage("Please select delivery date");
            return;
        }

        let deliverydate = this.state.deliverydate.replace('/', '-')
        deliverydate = deliverydate.replace('/', '-')
        await  this.engine.exportCSV("order","/export/"+deliverydate);
    }
    putrowstatus(item){

        if(item.status === 0){

            return "Pending"

        }else if(item.status === 1){

            return "Processing"
        }else if(item.status === 2){

            return "Completed"
        }

    }



    async callPage() {

        const response = await  this.engine.getItem(this.pathname);


        if(response && response.status === 200){



            this.setState({
                    isLoading: false,

                    dataSource: response.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            email: item.email,
                            notified:item.notified?"YES":"NO",
                            sourceuser:item.user ? item.user.source:"",
                            phone:item.user ? item.user.phone:"",
                            items:item.line_items && item.line_items.length,
                            user:item.user ? item.user.firstName+" "+item.user.lastName:"Guest :"+item.guestname,
                            source:item.source,
                            created:this.renderDate(item.createdAt),
                            updated:this.renderDate(item.updatedAt),
                            actions: (
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    render() {
        let {dataSource,isLoading} = this.state;

        let columns = [
            {
                Header: "User",
                accessor: "user"
            },
            {
                Header: "Notified",
                accessor: "notified",
                className:"bold",
            },

            {
                Header: "Registered From",
                accessor: "sourceuser"
            },
            {
                Header: "Email",
                accessor: "email"
            },
            {
                Header: "Phone",
                accessor: "phone"
            },
            {
                Header: "Items",
                accessor: "items"
            },
            {
                Header: "Source",
                accessor: "source",
            },
            {
                Header: "Created",
                accessor: "updated"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }


        ]




        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>

                                    <Row>




                                    </Row>

                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(CartAban);
