import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Row,
    Col,
    Table,
    Button, Modal
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-images-uploader/styles.css';
import 'react-images-uploader/font.css';
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';
import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";
import MiniFileManager from "../media/MiniFileManager";
import DynamicTbl from "./DynamicTbl";
export default class ProductAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        const html = '';
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);


        const htmlfull = '';
        const contentBlockfull = htmlToDraft(htmlfull);
        const contentStatefull = ContentState.createFromBlockArray(contentBlockfull.contentBlocks);
        const editorStatefull = EditorState.createWithContent(contentStatefull);


        this.state = {
            isLoading: true,
            name:"",
            namear:"",
            producttype:"simple",
            description:"",
            _id:"",
            serial:"",
            offerdatefrom:"",
            orfferdateto:"",
            price:"0",
            order:"0",
            saleprice:"0",
            slug:"",
            qty:"0",
            sku:"",
            slugx:"",
            discount:"0",
            wpid:"0",
            isseason:"0",
            isnew:"1",
            islimited:"0",
            active:"1",
            views:"0",
            full_picture:[],
            bundleproducts:[],
            images:[],
            category:[],
            categorylist:[],

            tag:[],
            taglist:[],

            loading : false,
            internalload:false,
            editorState : editorState,
            editorStatefull : editorStatefull,
            metakey:"",
            metadescription:"",

            measure:"",
            image:"",

            defaultvariation:"1",

            modalClassic:false,


            attributelistall:[],
            attributelist:[],
            variationlist:[],

        };
        this.pathname = "product";
        this.engine = new RequestEngine();
    }

    toggleModalMapClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };

    onPick(image){
        this.setState({
            image:image,
        })
    }
    renderMapCode() {
        const {modalClassic,image} = this.state;
        return (<>
            <Modal
                isOpen={modalClassic}
                toggle={this.toggleModalMapClassic}
            >
                <div className="modal-header justify-content-center english">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalMapClassic}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">Select Image </h4>
                </div>
                <div className="modal-body">
                    <MiniFileManager oldselection={null} onPick={this.onPick.bind(this)}/>
                    <div>
                        {image}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalMapClassic}
                        >
                            Hide
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    componentDidMount() {
        const edit = this.props.location.data
        debugger
        if (edit){
            const html = edit.description;
            const contentBlock = htmlToDraft(html);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            this.setState({
                internalload:true
            })
            let images = []
            edit.full_picture && edit.full_picture.map(i=>{
                images.push(i.url)
            })

            this.setState({
                ...edit,
                images:images,
                editorState:editorState,
                metakey:edit.meta.keys,
                metadescription:edit.meta.description,
                order:edit.order ? edit.order:"0"
            });
        }

        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("category");
        if(responseuni){
            const data = responseuni.data.data;
            let categorylist = []
            data.map(p =>{
                        categorylist.push( { value: p._id, label: p.name})
                }
            )
            this.setState({
                categorylist: categorylist,
                internalload:false
            });
        }
        responseuni = await this.engine.getItemlistDirect("attribute");
        if(responseuni){
            const data = responseuni.data.data;
            let attributelistall = []
            data.map(p =>{
                attributelistall.push( { value: p._id, label: p.name})
                }
            )
            this.setState({
                attributelistall,
            });
        }

        responseuni = await this.engine.getItemlistDirect("tag");
        if(responseuni){
            const data = responseuni.data.data;
            let taglist = []
            data.map(p =>{
                taglist.push( { value: p._id, label: p.name})
                }
            )
            this.setState({
                taglist: taglist,
            });
        }

        this.setState({
            isLoading:false
        })
    }

    handleValidSubmit = async () => {

        this.setState({
            loading: true
        })
        const {  _id,name,editorState ,price,
            saleprice,
            category,
            namear,
            slug,
            slugx,
            qty,
            isnew,
            defaultvariation,
            sku, metakey,serial,
            metadescription,attributeset,measure,image,order,tag,  offerdatefrom,
            orfferdateto,isseason,
            islimited,
            active,
            views,variationlist,attributelist}= this.state;



        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        let images = []


        let data = {};
        data._id=_id;
        data.variationlist = variationlist;
        let attributelistid = []
        attributelist.map(i=>{
            if(i.id){
                attributelistid.push(i.id)
            }else if(i.value){
                attributelistid.push(i.value)
            }else{
                attributelistid.push(i)
            }
        })
        data.attributelist = attributelistid;

        data.name=name;
        data.slug = slug;
        data.namear=namear;
        data.images=images;
        data.price=price;
        data.serial = serial;
        data.saleprice=saleprice;
        data.isnew = isnew;
        data.order = order;
        data.qty=qty;
        data.sku=sku;
        data.metakey=metakey;
        data.metadescription=metadescription;
        data.description=description;
        data.attributeset=attributeset;
        data.measure = measure;
        data.image = image;
        data.defaultvariation = defaultvariation;
        data.tag = tag;
        data.offerdatefrom = offerdatefrom;
        data.orfferdateto = orfferdateto;

        data.isseason =isseason;
        data.islimited =islimited;
        data.active =active;
        data.views =views;


        let categoryid = []
        category.map(i=>{
            if(i.value){
                categoryid.push(i.value)
            }else{
                categoryid.push(i)
            }
        })
        data.category=categoryid;
        let tagid = []
        tag.map(i=>{
            if(i.value){
                tagid.push(i.value)
            }else{
                tagid.push(i)
            }
        })
        data.tag=tagid;
        const response = await this.engine.saveItem(this.pathname,data)
        if(response && response.status === 200 && response.data.success){
            this.props.history.push('/admin/'+this.pathname);
        }else{
            Utilites.showErrorMessage(response.data.message)
        }
        this.setState({
            loading: false
        })

    };


    // on change attribute set
    triggerAction = async (event, stateName="",defaultvalue=[]) => {
        let value   = event.value

//// attributelist
        let responseuni = await this.engine.getItemlistDirect("attribute","/list/"+value);
        if(responseuni){
            const data = responseuni.data.data;
            let attributelist = []
            data.map(p =>{
                    attributelist.push( { value: p._id, label: p.name,childrenvalue:p.value.split("\n")})
                }
            )
            this.setState({
                attributelist: attributelist,
            });
        }

    };

    milestoneChangeValue = (event, stateName,index,subindex,takesubindex = false,addattrid=false) => {
        let servicefound = this[stateName][index]
        if(takesubindex){
            servicefound = this[stateName].find(x=>x.key=="variationattributevalue"+index+"x"+subindex)
        }
        let value = ""
        if(event && event.target){
            value = event.target.value;
        }else if(event && event.length){
            value = event;
        }else{
            value = event?event.value:[];
        }
        if(servicefound){
            servicefound.value =  value;
        }else{
            if(takesubindex){
                this[stateName].push({key:stateName+index+"x"+subindex,value:value,addattrid:addattrid? this.attributename[subindex].value:undefined})
            }else{
                this[stateName].push({key:stateName+index,value:value,addattrid:addattrid? this.attributename[subindex].value:undefined})
            }
        }
        this.forceUpdate()
    };


    fillAttribute(data){
        this.setState({
            variationlist:data
        })
    }

    renderAttributes(){
        const {variationlist,attributelist,attributelistall}= this.state;
        return (<>
            <CardHeader>
                <CardTitle tag="h4">Attributes 2 {variationlist.length}</CardTitle>
            </CardHeader>
            <CardBody>
                <SelectRow label="Attribute" isMulti={true}  name="attributelist"   data={attributelistall} defaultValue={attributelist && attributelist.map((item) => {
                    return attributelistall.find(v => v.value === item.value || v.value === item.id || v.value === item)
                })} changeInput={data =>
                    this.setState({ attributelist: data })
                }  />

               <DynamicTbl fillAttribute={this.fillAttribute.bind(this)} colx={attributelist} initialdata={variationlist}/>

            </CardBody>
        </>)
    }



    render() {
        const {loading,internalload,isLoading, categorylist,taglist,tag, name,editorState,category, price,
            saleprice,
            qty,
            sku,image,serial,namear,defaultvariation,offerdatefrom,
            orfferdateto, wpid,
            isseason,
            isnew,
            slugx,
            islimited,
            active,
            views}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">
                    {this.renderMapCode()}
                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Name" name="name" data={name}   changeInput={(e)=>{
                                            this.setState({
                                                name:e.target.value,
                                                slug:Utilites.generateSlug(e.target.value)
                                            })
                                        }} />
                                        <FormRow label="name arabic" name="namear" data={namear} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="sku" name="sku" data={sku} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="serial" type={"number"} name="serial" data={serial.toString()} changeInput={this.changeInput.bind(this)}  />
                                        <SelectRow label="category" isMulti={true}  name="category"   data={categorylist} defaultValue={category && category.map((item) => {
                                            return categorylist.find(v => v.value === item.value || v.value === item.id || v.value === item)
                                        })} changeInput={data =>
                                            this.setState({ category: data })
                                        }  />
                                        <FormRow label="price" name="price" type={"number"} data={price} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="sale price" type={"number"} required={false} name="saleprice" data={saleprice} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="Quantity" name="qty" required={false}  data={qty} type={"number"} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="slug" name="slugx" required={false}  readonly={true} data={slugx}   />
                                        <FormRow label="wordpress id" name="wpid" required={false}  readonly={true} data={wpid} type={"number"} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label={"isseason"} name="isseason" required={false}  data={isseason.toString()}  changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="isnew" name="isnew" required={false}  data={isnew.toString()}  changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="islimited" name="islimited" required={false}  data={islimited.toString()}  changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="active" name="active" required={false}  data={active.toString()}  changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="views" name="views" required={false}  data={views.toString()}  changeInput={this.changeInput.bind(this)}  />

                                        <FormRow label="offer date from" name="offerdatefrom" required={false}  data={offerdatefrom} type={"date"} changeInput={this.changeInput.bind(this)}  />
                                        <FormRow label="offer date to" name="orfferdateto" required={false}  data={orfferdateto} type={"date"} changeInput={this.changeInput.bind(this)}  />

                                        <Row>
                                            <Label sm="2">Description</Label>
                                            <Col sm="7" >
                                                <FormGroup   >
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="label-on-right" tag="label" sm="3">
                                                <code>required</code>
                                            </Col>
                                        </Row>
                                        {this.renderAttributes()}
                                        <FormRow  label="Default Variation Number" name="defaultvariation" type={"number"} data={defaultvariation.toString()} changeInput={this.changeInput.bind(this)}  />
                                        {!internalload &&<Row>
                                            <Label sm="2">Image  (600*600) </Label>
                                            <Col sm="7">
                                                <Button color="primary" className="pull-left" onClick={() =>
                                                    this.setState({
                                                        modalClassic:true
                                                    })
                                                }>
                                                    Select
                                                </Button>

                                                {image!=""&&<img src={Constants.serverlink+"/upload/"+image} style={{maxWidth:300}}  alt={""}/>}
                                            </Col>
                                        </Row>}
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


