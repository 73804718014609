import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import Checkboxrow from "../../components/Checkbox/Checkboxrow";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";

export default class CompoundAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            name:"",
            namear:"",
            _id:"",
            loading : false,
        };
        this.pathname = "compound";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
            });
        }


    }


    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })




        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200 && response.data.success){
            this.props.history.push('/admin/'+this.pathname);
        }else{
            Utilites.showErrorMessage(response.data.message)
        }


        this.setState({
            loading: false
        })

    };

    onChangePic(e) {
        this.setState({picture:e.target.files[0]})
    }
    render() {
        // taking all the states
        const {loading,isLoading,categorylist,order, name,namear,parent,status }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Name" name="name" data={name}   changeInput={(e)=>{
                                            this.setState({
                                                name:e.target.value,
                                            })
                                        }} />

                                        <FormRow label="Name arabic" name="namear" data={namear}   changeInput={(e)=>{
                                            this.setState({
                                                namear:e.target.value,
                                            })
                                        }} />



                                    </CardBody>




                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


