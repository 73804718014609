import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import { AvForm } from 'availity-reactstrap-validation';
import {Utilites} from "../../core/Utilites";
import CoreEngine from "../../core/CoreEngine";
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import MiniFileManager from "./MiniFileManager";
import RequestEngine from "../../core/RequestEngine";

class FileManagerScreen extends CoreEngine {



    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            picture:"",
            fullpicture:"",
            path:"/"
        };
        this.pathname = "media";
        this.engine = new RequestEngine();
        this.sidepicture = "";
    }



    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })


        let data  = {...this.state}
        data.sidepicture = this.sidepicture;
        const response = await this.engine.saveItem(this.pathname,data)
        if(response && response.status === 200){
          window.location.reload();
        }
        this.setState({
            loading: false
        })

    };

    renderUpload() {
        // taking all the states
        const {loading,isLoading,  }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Label sm="2">Zip  file </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload placeholder={this.state.fullpicture} isfile={true} labelupload="Select file" onChange={(e) => {
                                                        Utilites.getFileBase64(e.target.files[0], (result) => {
                                                            this.sidepicture = result;
                                                        });
                                                    }}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Upload</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    forderpath(path){
        this.setState({
            path
        })
    }
    render() {





        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Media Manager</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {this.renderUpload()}
                                    <MiniFileManager forderpath={this.forderpath.bind(this)}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(FileManagerScreen);
