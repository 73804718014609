import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import SelectRow from "../../components/Select/SelectRow";


export default class RowUserAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            full_name: "",
            fullpicture:"",
            email:"",
            phone:"",
            password: "",
            username:"",
            comments:"",
            first_name:"",
            last_name:"",
            birthday:"",
            picture:"",
            gender:"",
            status:"",
            newpassword:"",
            _id : ""
        };
        this.engine = new RequestEngine();
    }



    componentDidMount() {
        const edit = this.props.location.data

        if (edit){
            this.setState({
                ...edit,
            });


        }

        this.prepareData()
    }

    async prepareData(){
    }



    handleValidSubmit = () => {

        this.engine.saveUserMobile(this.state,(response) => {
            console.log(response.status);
            if(response.status === 200){
                this.props.history.push('/admin/user');
            }
            this.setState({
                btnloading: false
            })
        });



    };






    render() {
        // taking all the states
        const {
            password,
            username,
            comments,
            phone,
            newpassword,
            btnloading,_id} = this.state;
        const {normaluser} = this.props
        const edit = this.props.location.data
        return (
            <>
                <div className="content english">
                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>


                                        <FormRow label="Full name" name="full_name" data={this.state.full_name} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Email" name="email" data={this.state.email} changeInput={this.changeInput.bind(this)} />

                                        <FormRow label="Phone" required={false}  name="phone" data={phone} changeInput={this.changeInput.bind(this)} />
                                        {!edit && <FormRow label="Password " type="password" name="password" data={password} changeInput={this.changeInput.bind(this)} />}

                                        {!normaluser && (edit && <FormRow label="New Password " required={false} type="password" name="newpassword" data={newpassword} changeInput={this.changeInput.bind(this)} />)}

                                        <FormRow required={false} label="Comment" type="textarea" name="comments" data={comments} changeInput={this.changeInput.bind(this)} />


                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


