import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Row,
    Col,
    Modal,
    DropdownItem,
    UncontrolledDropdown,
    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle, CardTitle, Label, FormGroup, CardFooter
} from "reactstrap";

import ReactDragListView from 'react-drag-listview/lib/index.js';
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import {AvForm} from "availity-reactstrap-validation";
import FormRow from "../../components/Row/FormRow";
import SelectRow from "../../components/Select/SelectRow";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";


class ProductList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
            selected: [],
            dragdata:[],
            modalClassic:false,
            modalClassicCity:false,
        };
        this.pathname = "product";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage(0)
    }




    async handleDelete(valueid) {

        if(window.confirm("are you sure ?")){
            console.log(valueid);
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem(this.pathname,valueid)
            if(result){
                this.handlePage(0);
            }
            this.props.loadingAction(false)
        }



    }

    async updateData() {
        console.log();
        this.props.loadingAction(true)
        const result = await this.engine.updateData(this.pathname)
        if(result){
            this.handlePage(0);
        }
        this.props.loadingAction(false)

    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>

                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    globalActions() {

        return (<UncontrolledDropdown className="pull-left" >
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Global Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => {

                    this.updateData()
                }}>Update Data From Excel</DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Featured</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Limited</DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected New</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Out Of Stock</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected Active</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected InActive</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {

                }}>Mark Selected In Season</DropdownItem>

                <DropdownItem  eventKey="2" onClick={() => {
                    this.setState({
                        modalClassicCity:true
                    })
                }}>Update City</DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => {
                    this.setState({
                        modalClassic:true
                    })
                }}>Update Order</DropdownItem>


            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    toggleModalMapClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };
    toggleModalMapClassicCity = () => {
        this.setState({
            modalClassicCity: !this.state.modalClassicCity
        });
    };



    renderModelView() {
        const {modalClassic} = this.state;
        return (<>
            <Modal
                isOpen={modalClassic}
                toggle={this.toggleModalMapClassic}
            >
                <div className="modal-header justify-content-center english">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalMapClassic}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">Update Order</h4>
                </div>
                <div className="modal-body">

                    {this.renderDrag()}

                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalMapClassic}
                        >
                            Hide
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }

    renderModelCityView() {
        const {modalClassicCity,city} = this.state;
        return (<>
            <Modal
                isOpen={modalClassicCity}
                toggle={this.toggleModalMapClassicCity}
            >
                <div className="modal-header justify-content-center english">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalMapClassicCity}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">Update Product City</h4>
                </div>
                <div className="modal-body">

                    <AvForm  onValidSubmit={() => {

                    }} className="form-horizontal" id="TypeValidation">
                        <Card>
                            <CardBody>
                               <SelectRow label="City"  name="city" isMulti={true}  defaultValue={city}  data={this.createSelectValue(["Maka","Riyadth","Khobar"])} changeInput={this.changeInput.bind(this)} />


                            </CardBody>







                            <CardFooter className="text-center">
                                <ButtonLoader color="primary">Save</ButtonLoader>
                            </CardFooter>
                        </Card>
                    </AvForm>


                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalMapClassic}
                        >
                            Hide
                        </Button>

                    </div>

                </div>
            </Modal>
        </>)
    }





    checkstatus(item) {
        if(item.status === 0){
            return ("InActive")
        }else if(item.status === 1){
            return ("Active")
        }else if(item.status === -1){
            return ("Out Of Stock")
        }
    }



    async callPage(page=0,filtered = []) {

        this.setState({
            isLoading:true
        })
        const response = await  this.engine.postItem(this.pathname,"/list/"+page,filtered);



        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                count:response.data.data.count,
                page:page,
                dragdata: response.data.data.data.map((item, key) => {
                    return {
                        id: item.id,
                        title: item.name ,
                        main_picture:item.main_picture ,
                    };
                }),
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            name: item.name ,
                            img: item.main_picture.length>1 ? <img key={key}  src={item.main_picture} className={"imgsponser"} />:"-" ,
                            price: item.price ,
                            sku: item.sku ,
                            qty: item.qty,
                            serial: item.serial,
                            status: this.checkstatus(item) ,
                            raw_status: this.checkstatus(item),
                            description: item.description,
                            category: item.main_category && item.main_category.name,
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }

    toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
            selected: newSelected,
        });
    }



    renderDrag() {
        const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const dragdata = [...that.state.dragdata];
                const item = dragdata.splice(fromIndex, 1)[0];
                dragdata.splice(toIndex, 0, item);
                that.setState({ dragdata });
            },
            nodeSelector: 'li',
            handleSelector: 'a'
        };

        return (
            <Row>
                <Col md="12">
                    <ReactDragListView {...dragProps}>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <ol style={{margin:"auto"}}>
                                        {this.state.dragdata.map((item, index) => (
                                            <li key={index} className={"dragmin"} style={{width:700}}>
                                                {item.main_picture ? <img key={index}  src={item.main_picture} className={"imgsponser"} />:"-"} {item.title}
                                                <a href="#" className="pull-right" style={{
                                                    position: "relative",
                                                    top: 30
                                                }} >Drag</a>
                                            </li>
                                        ))}
                                    </ol>
                                </Card>
                            </Col>
                        </Row>
                    </ReactDragListView>
            </Col>
            </Row>
        );
    }
    render() {
        const {dataSource,isLoading,count,page} = this.state;



        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })

        let columns = [
            {
                id: "checkbox",
                Header: "#",
                sortable: false,
                filterable: false,
                width:50,
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selected[original.data.id] === true}
                            onChange={() => this.toggleRow(original.data.id)}
                        />
                    );
                },
            },
            {
                Header: "Image",
                accessor: "img",
                sortable: false,
                filterable: false
            },
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Sku",
                accessor: "sku"
            },
            {
                Header: "category",
                accessor: "category",
                filterable: false
            },
            {
                Header: "serial",
                accessor: "serial"
            },
            {
                Header: "status",
                accessor: "status"
            },

            {
                Header: "Created",
                accessor: "created"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
                draggable:true
            }
        ]


        return (
            <>

                <div className="content">
                    {this.renderModelView()}
                    {this.renderModelCityView()}
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>

                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.props.history.push('/admin/'+this.pathname+'/upload')
                                    }>
                                        Upload
                                    </Button>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>

                                    {this.globalActions()}
                                </CardHeader>
                                <CardBody>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        onPageChange={(pageIndex) => {
                                            console.log(pageIndex)
                                        }}
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                isLoading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page,filtered)
                                        }}
                                        pages={count}
                                        defaultPage={page}
                                        sortable={true}
                                        rowsText={"xxx"}
                                        showPaginationBottom={false}
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ProductList);
