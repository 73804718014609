import React from "react";
import { CSVLink } from "react-csv";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";


import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar, Bubble, Line, Pie, Scatter} from 'react-chartjs-2';
import * as PropTypes from "prop-types";



export const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};


function Chart(props) {
    return null;
}

Chart.propTypes = {
    data: PropTypes.shape({datasets: PropTypes.any, labels: PropTypes.arrayOf(PropTypes.string)}),
    type: PropTypes.string
};
class TopSellingProduct extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            topproducts:[],
            specialproduct:[],
            csvData:[]
        };
        this.pathname = "reportproducts";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }



    async handleDelete(valueid) {
        console.log(valueid);
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)

    }







    processData(rows,label="vip") {

        let datasets= []
        let labels= []
        rows.map((item, key) => {
            labels.push(item.name)
            datasets.push(parseInt(item.views))
        })

        let output = {
            labels,
            datasets: [
                {
                    label: label,
                    data: datasets,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }

        return output;

    }


    processDataCart(rows,label="atc") {

        let datasets= []
        let labels= []
        rows.map((item, key) => {
            labels.push(item.name)
            datasets.push(parseInt(item[label]))
        })

        let output = {
            labels,
            datasets: [
                {
                    label: "",
                    data: datasets,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }

        return output;

    }





    async callPage() {

        const response = await  this.engine.getItem(this.pathname);


        if(response && response.status === 200){

            const specialproduct = response.data.data.specialproduct;
            const  topproducts= response.data.data.topproducts;

            let csvData = []
            specialproduct.map(i=>{
                csvData.push({
                    sku:i.sku,
                    product_name:i.name,
                    count:i.views,
                    type:"products views",
                })
            });

            topproducts.map(i=>{
                csvData.push({
                    sku:i.sku,
                    product_name:i.name,
                    count:i.atc,
                    type:"products ordered",
                })
            });

            this.setState({
                    isLoading: false,
                    specialproduct:this.processData(specialproduct,"views"),
                    topproducts:this.processDataCart(topproducts,"atc"),
                csvData
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }




    render() {
        const {vip,isLoading,
            specialproduct,
            topproducts,
            hightuser,
            medium,
            lowuser} = this.state;


        if(isLoading){
            return this.renderProgress();

        }

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>
                                    <CSVLink filename={"export"} className="pull-right" data={this.state.csvData}>Export </CSVLink>
                                </CardHeader>
                                <CardBody>

                                    <h3>Special Products (TOP VIEWS)</h3>
                                    <Bar options={options} data={specialproduct} />


                                    <h3>Top Products (TOP ORDERED) </h3>
                                    <Line options={options} data={topproducts} />


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(TopSellingProduct);
