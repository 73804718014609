import React from "react";
import { withRouter} from "react-router-dom";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col, CardFooter
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import FormRow from "../../components/Row/FormRow";
import {AvForm} from "availity-reactstrap-validation";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import Constants from "../../core/Constants";

class OrderInfo extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            invloading:false,
            orderinfo:{},
            restaurantinfo:{},
            orderid: "",
        };
        this.pathname = "order";
        this.engine = new RequestEngine();
    }

    componentDidMount() {

        const id= this.props.match.params.id
        this.setState({
            orderid:id
        },()=>this.callPage())

    }


    async handleViewInvoiceExport() {



        this.setState({
            invloading:true

        })
        const response = await  this.engine.getItem("invoice","/export/"+this.state.orderid);

        if(response && response.status === 200){

            this.setState({
                invloading: false,
                }
            );
            debugger
            window.open(Constants.serverlink+"/upload/"+response.data.data.url, "_blank" );
        }
    }

    async callPage() {
        debugger
        const response = await  this.engine.getItem(this.pathname,"/view/"+this.state.orderid);

        if(response && response.status === 200){

            this.setState({
                    isLoading: false,
                    orderinfo:response.data.data.orderinfo,
                }
            );
        }
    }



    renderInCpm(){
        const {orderinfo} = this.state;
        return (
            <>
                <FormRow label="compound" readonly={true} viewonly={true}  data={orderinfo.compound}  />
                <FormRow label="complex" readonly={true} viewonly={true}  data={orderinfo.complex}  />
                <FormRow label="appartment" readonly={true} viewonly={true}  data={orderinfo.appartment}  />
             </>
        );
    }


    renderNoInCpm(){
        const {orderinfo} = this.state;
        return (
            <>
                <FormRow label="area" readonly={true} viewonly={true}  data={orderinfo.area}  />
                <FormRow label="street" readonly={true} viewonly={true}  data={orderinfo.street}  />
                <FormRow label="apparment,suite" readonly={true} viewonly={true}  data={orderinfo.suite}  />
            </>
        );
    }

    render() {
        let {orderinfo,isLoading,invloading} = this.state;

        if(isLoading){
            return  this.renderProgress()
        }

        let hasdiscount = false;
        if(orderinfo?.discount>2){
            hasdiscount = true;
        }

        let subtotal = 0;

        return (
            <>

                <div className="content english">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Order Details</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AvForm   className="form-horizontal" id="TypeValidation">
                                    <FormRow label="Order ID #" readonly={true}  viewonly={true}  data={orderinfo.ordernumber}  />
                                    <FormRow label="Order Status" readonly={true} viewonly={true}  data={orderinfo.status}  />
                                    <FormRow label="Order Source" readonly={true} viewonly={true}  data={orderinfo.source}  />
                                    <FormRow label="User" readonly={true}  viewonly={true}  data={orderinfo.user ? orderinfo.user.firstName+" "+orderinfo.user.lastName : orderinfo.guestname}  />
                                    <FormRow label="Email" readonly={true}  viewonly={true}  data={orderinfo.user ? orderinfo.user.email : orderinfo.guestemail}  />
                                    <FormRow label="Phone" readonly={true}  viewonly={true}  data={orderinfo.user ? orderinfo.user.phone : orderinfo.guestphone}  />
                                    <FormRow label="Whatapp" readonly={true}  viewonly={true}  data={orderinfo.user ? orderinfo.user.whatapp : orderinfo.whatapp}  />


                                        <FormRow label="Discount" readonly={true} viewonly={true}  data={orderinfo.discount}  />
                                        <FormRow label="Discount type" readonly={true} viewonly={true}  data={orderinfo.discounttype}  />
                                    <FormRow label="Order Created" readonly={true}  viewonly={true}  data={this.renderDate(orderinfo.createdAt)}  />

                                        {orderinfo.incompound ? this.renderInCpm():this.renderNoInCpm()}

                                        <FormRow label="Whatapp" readonly={true}  viewonly={true}  data={orderinfo.user ? orderinfo.user.whatapp : orderinfo.whatapp}  />

                                        {orderinfo.paymenttype==1 && <FormRow label="Payment Method" readonly={true}  viewonly={true}  data={"Cash On Delivery"}  />}
                                        {orderinfo.paymenttype==2 && <FormRow label="Payment Method" readonly={true}  viewonly={true}  data={"Visa / Master Card"}  />}
                                        {orderinfo.paymenttype==3 && <FormRow label="Payment Method" readonly={true}  viewonly={true}  data={"Mada Online"}  />}
                                        {orderinfo.paymenttype==4 && <FormRow label="Payment Method" readonly={true}  viewonly={true}  data={"Mada Delivery"}  />}
                                        <FormRow label="HyperPay code" readonly={true} viewonly={true}    data={orderinfo && orderinfo.paymentcoderesult}  />
                                        <FormRow label="HyperPay description" readonly={true} viewonly={true}    data={orderinfo && orderinfo.paymentcodedescription}  />

                                        <FormRow label="Delivery Date" readonly={true} viewonly={true}   data={orderinfo.deliverydate}  />
                                        <FormRow label="Delivery Time" readonly={true} viewonly={true}   data={orderinfo.deliverytime}  />
                                        <FormRow label="Source" readonly={true} viewonly={true}   data={orderinfo.source}  />
                                        <FormRow label="Email Sent to Daily Order" readonly={true} viewonly={true}   data={orderinfo.sent.toString()}  />
                                        <FormRow label="Driver ID" readonly={true} viewonly={true}    data={orderinfo && orderinfo.driver ?orderinfo.driver.driverid:""}  />
                                        <FormRow label="Order Sequence" readonly={true} viewonly={true}    data={orderinfo && orderinfo.orderSequence}  />

                                    <FormRow label="Order Note" readonly={true} viewonly={true} type={"textarea"}   data={orderinfo && orderinfo.customer_note}  />
                                    </AvForm>
                                </CardBody>



                                <CardHeader>
                                    <CardTitle tag="h4">Products</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Attribute</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orderinfo.line_items && orderinfo.line_items.map((item,index)=>{
                                            let attribute = item.attr1;
                                            let quantity = item.quantity?item.quantity:item.qty // because on web its qty
                                            if(item.attr2 && item.attr2.length>0){
                                                attribute = item.attr1+"/"+item.attr2;
                                            }
                                            subtotal += (quantity*item.price)
                                            return <tr key={index}>
                                                <td>{item.name }</td>
                                                <td> {attribute}</td>
                                                <td> {quantity}</td>
                                                <td> {item.price}</td>
                                            </tr>
                                        })}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right bold" >Subtotal SAR {parseFloat(subtotal).toFixed(2)} </td>
                                        </tr>
                                        {hasdiscount && <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right bold" style={{color:"red"}}>Discount SAR -{parseFloat((parseFloat(subtotal)*parseFloat(orderinfo.discount))/100).toFixed(2)} </td>
                                        </tr>}
                                        {hasdiscount && <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right bold" >New Total SAR {parseFloat(parseFloat(subtotal)-parseFloat(((parseFloat(subtotal)*parseFloat(orderinfo.discount))/100))).toFixed(2)} </td>
                                        </tr>}
                                        {hasdiscount ? <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right bold" >VAT 15%  SAR {parseFloat(parseFloat(parseFloat(subtotal)-parseFloat(((parseFloat(subtotal)*parseFloat(orderinfo.discount))/100)))*0.15).toFixed(2)} </td>
                                        </tr>:<tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right bold" >VAT 15%  SAR {parseFloat(parseFloat(parseFloat(subtotal)-parseFloat(((parseFloat(subtotal))/100)))*0.15).toFixed(2)} </td>
                                        </tr>}
                                        {hasdiscount ? <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right bold">Total SAR {parseFloat(parseFloat(parseFloat(parseFloat(subtotal)-parseFloat(((parseFloat(subtotal)*parseFloat(orderinfo.discount))/100)))*0.15)+(parseFloat(parseFloat(subtotal)-parseFloat(((parseFloat(subtotal)*parseFloat(orderinfo.discount))/100))))).toFixed(2)}</td>
                                        </tr>:<tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="text-right bold">Total SAR {parseFloat(parseFloat(parseFloat(parseFloat(subtotal)-parseFloat(((parseFloat(subtotal))/100)))*0.15)+(parseFloat(parseFloat(subtotal)-parseFloat(((parseFloat(subtotal))/100))))).toFixed(2)}</td>
                                        </tr>}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <ButtonLoader color="primary" loading={invloading} onClick={()=>this.handleViewInvoiceExport()} >View Invoice</ButtonLoader>
                                </CardFooter>
                            </Card>

                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(withRouter(OrderInfo));
