import React from "react";

import CoreEngine from "../../core/CoreEngine";
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import Constants from "../../core/Constants";
import { FileManager, FileNavigator } from '@opuscapita/react-filemanager';
import connectorNodeV1 from '@opuscapita/react-filemanager-connector-node-v1';


const apiOptions = {
    ...connectorNodeV1.apiOptions,
    apiRoot: Constants.mediaserverlink
}

class MiniFileManager extends CoreEngine {
    render() {

        const {onPick,forderpath} = this.props;
        return (
            <div style={{ height: '480px' }}>
                <FileManager>
                    <FileNavigator
                        id="filemanager-1"
                        api={connectorNodeV1.api}
                        apiOptions={apiOptions}
                        capabilities={connectorNodeV1.capabilities}
                        listViewLayout={connectorNodeV1.listViewLayout}
                        viewLayoutOptions={connectorNodeV1.viewLayoutOptions}
                       // initialResourceId={"LzY4NTNhY2QwLWJlYzgtNGU2ZS1iNTI3LTc3ODgwZjEwMTFlYS5qcGc"}

                        onResourceItemClick={
                            ({ event, number, rowData }) => {
                                console.log('onResourceItemClick', event, number, rowData)
                                if(rowData.type=="file"){
                                    let path = "";
                                    for(let i=0;i<rowData.ancestors.length;i++){
                                        path += rowData.ancestors[i].name+"/";
                                    }
                                    path += rowData.name;
                                    console.log("the path of the file is ",path)
                                    {onPick && onPick(path)}
                                }
                                if(rowData.type=="dir"){
                                    let path = "";
                                    for(let i=0;i<rowData.ancestors.length;i++){
                                        path += rowData.ancestors[i].name+"/";
                                    }
                                    path += rowData.name;
                                    console.log("the path of the file is ",path)
                                    {forderpath && forderpath(path)}
                                }
                            }
                        }
                    />
                </FileManager>
            </div>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(MiniFileManager);
