import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,

    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";

class SearchList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
        };
        this.pathname = "search";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage(0)
    }



    async handleDelete(valueid) {
        console.log(valueid);
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage(0);
        }
        this.props.loadingAction(false)

    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }








    async callPage(page,filtered = []) {

        const response = await  this.engine.postItem(this.pathname,"/list/"+page,filtered);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    count:response.data.data.count,
                    page:page,
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            resultcount:item.resultcount,
                            data:item,
                            keyword: item.keyword ,
                            hits:item.hits,
                            created:this.renderDate(item.createdAt),
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage(page) {
        this.props.loadingAction(true);
        this.callPage(page)
    }





    render() {
        const {dataSource,isLoading,count,page} = this.state;

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })

        let columns = [


            {
                Header: "Keyword",
                accessor: "keyword"
            },
            {
                Header: "ResultCount",
                accessor: "resultcount"
            },
            {
                Header: "Hits",
                accessor: "hits"
            },



            {
                Header: "Created",
                accessor: "created"
            },

        ]


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>
                                </CardHeader>
                                <CardBody>

                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        onPageChange={(pageIndex) => {
                                            console.log(pageIndex)
                                        }}
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                isLoading:true
                                            })
                                            const filtered = state.filtered
                                            this.callPage(state.page,filtered)
                                        }}
                                        pages={count}
                                        defaultPage={page}
                                        //showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />



                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(SearchList);
