import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import Checkboxrow from "../../components/Checkbox/Checkboxrow";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";

export default class CategoryAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            name:"",
            namear:"",
            status:false,
            slug:"",
            description:"",
            descriptionar:"",
            inhome:"no",
            order:"0",
            _id:"",
            fullpicture:"",
            picture:"",
            parent:"",
            categorylist:[],
            loading : false,
            ismenu:"no"
        };
        this.pathname = "category";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
                fullpicture:edit.picture.length>1 ? Constants.serverlink+"/upload/"+edit.picture:"",
                parent:edit.category && edit.category._id,
                status:edit.status==1?true:false
            },()=>this.prepareData());
        }else{
            this.prepareData()
        }


    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("category");
        const {_id} = this.state
        if(responseuni){
            const data = responseuni.data.data;
            let categorylist = []
            categorylist.push( { value: "", label: "No Parent"})
            data.map(p =>{
                if(_id!=p._id){
                    categorylist.push( { value: p._id, label: p.name})
                }
            }
            )

            this.setState({
                categorylist: categorylist,
                isLoading:false
            });
        }
    }


    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        const {  _id,slug,name,status,order,inhome,description,picture ,parent}= this.state;

        const formData = new FormData();
        formData.append('picture',picture)
        formData.append('_id',_id)
        formData.append('name',name)
        formData.append('status',status=="true"?0:1)
        formData.append('parent',parent)
        formData.append('description',description)
        formData.append('inhome',inhome)
        formData.append('order',order)

        const response = await this.engine.saveItemData(this.pathname,formData)

        if(response && response.status === 200 && response.data.success){
            this.props.history.push('/admin/'+this.pathname);
        }else{
            Utilites.showErrorMessage(response.data.message)
        }


        this.setState({
            loading: false
        })

    };

    onChangePic(e) {
        this.setState({picture:e.target.files[0]})
    }
    render() {
        // taking all the states
        const {loading,isLoading,categorylist,order, name,description,parent,status }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="Name" name="name" data={name}   changeInput={(e)=>{
                                            this.setState({
                                                name:e.target.value,
                                                slug:Utilites.generateSlug(e.target.value)
                                            })
                                        }} />
                                        <FormRow label="Category order"  required={false} name="order" data={order} type={"number"}   changeInput={(e)=>{
                                            this.setState({
                                                order:e.target.value,
                                            })
                                        }} />


                                        <SelectRow label="Parent category"  name="parent"  defaultValue={parent}  data={categorylist} changeInput={this.changeInput.bind(this)} />

                                        <FormRow required={false} label="description" name="description" type={"textarea"} data={description} changeInput={this.changeInput.bind(this)}  />
                                        <Checkboxrow label="Activated" name="status" data={status}  changeInput={this.changeInput.bind(this)} />

                                        <Row>
                                            <Label sm="2">Image  (600*600) </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select Image" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>







                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


