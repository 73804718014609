
import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";

export default class AssignOrderDriver extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            ordernumberstring:"",
            _id:"",
            driver:"",
            driverlist:[],


            loading : false
        };
        this.pathname = "assignorder";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
                driver:edit.driver && edit.driver._id
            });
        }
        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("driver");
        const {_id} = this.state
        if(responseuni){
            const data = responseuni.data.data;
            let driverlist = []
            data.map(p =>{
                driverlist.push( { value: p._id, label: p.firstName+" "+p.lastName})

                }
            )

            this.setState({
                driverlist,
                isLoading:false
            });
        }
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })



        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/order');
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading,  name,ordernumberstring,driver,driverlist }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label="order number" viewonly={true} name="ordernumberstring" data={ordernumberstring}   changeInput={this.changeInput.bind(this)} />

                                        <SelectRow label="driver"  name="driver"  defaultValue={driver}  data={driverlist} changeInput={this.changeInput.bind(this)} />


                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


