import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow"
import Checkboxrow from "../../components/Checkbox/Checkboxrow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';



export default class AttributeAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            name:"",
            value:"",
            _id:"",
            attributeset:"",
            attributesetlist:[],
            type:"dropdown",
            typelist:this.createSelectValue(["text","number","textarea","yes/no","dropdown"],false),
            loading : false,
            canfilter:false,
            required:false,
        };
        this.pathname = "attribute";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
                attributeset:edit.attributeset && edit.attributeset._id

            },()=>this.prepareData());
        }else{
            this.prepareData()
        }


    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("attributeset");
        const {_id} = this.state
        if(responseuni){
            const data = responseuni.data.data;
            let attributesetlist = []
            data.map(p =>{
                    if(_id!=p._id){
                        attributesetlist.push( { value: p._id, label: p.name})
                    }
                }

            )

            this.setState({
                attributesetlist: attributesetlist
            });
        }
    }


    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        const {  _id,name,value,attributeset,type,required}= this.state;


        const data={
            attributeset : attributeset,
            name:name,
            value:value,
            type:type,
            _id:_id,
            required:required
        }
        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading, attributesetlist,type,typelist, name,value,attributeset,canfilter,required }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <SelectRow label="Attribute set"  name="attributeset"  defaultValue={attributeset}  data={attributesetlist} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Title" name="name" data={name}   changeInput={this.changeInput.bind(this)} />
                                        <Checkboxrow label="Required" name="required" data={required}  changeInput={this.changeInput.bind(this)} />
                                        <FormRow required={false} label="Value" name="value" style={{height:300}} type={"textarea"} data={value} changeInput={this.changeInput.bind(this)}  />
                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


