
import _ from "lodash";
import React from "react";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import { CSVLink } from "react-csv";


import {Bar, Bubble, Line, Pie, Scatter} from 'react-chartjs-2';
import * as PropTypes from "prop-types";


export const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Freshvery Bar Chart',
        },
    },
};


function Chart(props) {
    return null;
}

Chart.propTypes = {
    data: PropTypes.shape({datasets: PropTypes.any, labels: PropTypes.arrayOf(PropTypes.string)}),
    type: PropTypes.string
};
class UserVip extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            csvData:[],
            datax:[],
            vip:[],
            newuser:[],
            activeuser:[],
            hightuser:[],
            medium:[],
            lowuser:[]
        };
        this.pathname = "reportuser";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }


     processData(rows,label="vip") {

        let datasets= []
        let labels= []
         rows.map((item, key) => {
            labels.push(item.user)
             datasets.push(parseInt(item.total))
        })

        let output = {
            labels,
            datasets: [
                {
                    label: label,
                    data: datasets,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }

        return output;

    }


    processUserStatus(usernewnoorder,usernewactive,useridle,useractive,i) {

        let userstatus = "active";
        let found = useractive.find(x=>x.id==i.id);
        if(found){
            userstatus = "active"
        }
        found = useridle.find(x=>x.id==i.id);
        if(found){
            userstatus = "idle"
        }

        found = usernewactive.find(x=>x.id==i.id);
        if(found){
            userstatus = "new active"
        }

        found = usernewnoorder.find(x=>x.id==i.id);
        if(found){
            userstatus = "new not active"
        }
        return userstatus;

    }


    checkRealRank(month) {
        let testvalue = [];
        Object.entries(month).forEach(([key, value]) => {
            testvalue.push(value);
        });
        let maxvalue = Math.max(...testvalue)
        let rank = "Low";
        //  vip , hight , medium , low
        if(maxvalue>1000){
            rank = "Vip";
        }else if(maxvalue>500){
            rank = "High";
        }else if(maxvalue>250){
            rank = "Medium";
        }
        return rank

    }


    processActive(rows,label="vip") {

        let datasets= []
        let labels= []
        rows.map((item, key) => {
            labels.push(item.firstName+" "+item.lastName)
            datasets.push(parseInt(item.total))
        })

        let output = {
            labels,
            datasets: [
                {
                    label: label,
                    data: datasets,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
        }

        return output;

    }




    async callPage() {

        const response = await  this.engine.getItem(this.pathname);

        if(response && response.status === 200){


              const useractive= response.data.data.useractive;
              const usernewactive= response.data.data.usernewactive;
              const useridle= response.data.data.useridle;
              const usernewnoorder= response.data.data.usernewnoorder;


              const rankidle = response.data.data.rankidle;
              const vip = response.data.data.vip;
              const hightuser= response.data.data.hightuser;
              const medium= response.data.data.medium;
              const lowuser= response.data.data.lowuser;


            let csvData = []
            vip.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.monthorders),
                    status:this.processUserStatus(usernewnoorder,usernewactive,useridle,useractive,i), // active , idle , new , new but not active
                    //total:i.total,
                    ...i.monthorders
                })
            });

            hightuser.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.monthorders),
                    status:this.processUserStatus(usernewnoorder,usernewactive,useridle,useractive,i), // active , idle , new , new but not active
                    //total:i.total,
                    ...i.monthorders
                })
            });

            medium.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.monthorders),
                    status:this.processUserStatus(usernewnoorder,usernewactive,useridle,useractive,i), // active , idle , new , new but not active
                    //total:i.total,
                    ...i.monthorders
                })
            });

            lowuser.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.monthorders),
                    status:this.processUserStatus(usernewnoorder,usernewactive,useridle,useractive,i), // active , idle , new , new but not active
                    //total:i.total,
                    ...i.monthorders
                })
            });


            rankidle.map(i=>{
                csvData.push({
                    id:i.id,
                    full_name:i.user,
                    phone:this.processPhone(i.phone),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:this.checkRealRank(i.monthorders),
                    status:this.processUserStatus(usernewnoorder,usernewactive,useridle,useractive,i), // active , idle , new , new but not active
                    //total:i.total,
                    ...i.monthorders
                })
            });

            let onerowofmonst = rankidle[0].monthorders;

            for (const [key, value] of Object.entries(onerowofmonst)) {
                onerowofmonst[key]= "0"
            }
            //
            useridle.map(i=>{

                csvData.push({
                    id:i.id,
                    full_name:i.full_name,
                    phone:this.processPhone(i.phone),
                    email:i.email,
                    created:this.renderDateOnly(i.createdAt),
                    rank:"very low", // vip , hight , medium , low
                    status:'never active', // active , idle , new , new but not active
                    //total:i.total,
                    ...onerowofmonst
                })
            });



            csvData= _.uniqBy(csvData, function (e) {
                return e.email;
            });
            this.setState({
                    isLoading: false,
                vip:this.processData(vip),
                newuser:this.processData(usernewactive,""),
                activeuser:this.processActive(useractive,""),
                hightuser:this.processData(hightuser,""),
                medium:this.processData(medium,""),
                lowuser:this.processData(lowuser,""),
                csvData
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }




    render() {
        const {vip,isLoading,
            newuser,
            activeuser,
            hightuser,
            medium,
            lowuser} = this.state;



        if(isLoading){
            return this.renderProgress();

        }

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>
                                    <CSVLink filename={"export"} className="pull-right" data={this.state.csvData}>Export </CSVLink>
                                </CardHeader>
                                <CardBody>

                                    <h3>VIP( Over 1,000 SAR monthly/ AVG last 90 days ) </h3>
                                    <Bar options={options} data={vip} />


                                    <h3>High ( Between 500 & 1000 Monthly/ AVG last 90 days )</h3>
                                    <Line options={options} data={hightuser} />


                                    <h3>Medium ( Between 250 & 500 Monthly/ AVG last 90 days )</h3>
                                    <Line options={options} data={medium} />

                                    <h3>Low ( Less than 250 monthly / AVG last 90 days )</h3>
                                    <Line options={options} data={lowuser} />


                                    <h3>Active ( active for a period last 30 days )</h3>
                                    <Line options={options} data={activeuser} />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(UserVip);
