import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import SelectRow from "../../components/Select/SelectRow";


export default class ManagerAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            managertypelist:[],
            full_name: "",
            fullpicture:"",
            email:"",
            phone:"",
            password: "",
            username:"",
            vendorid:"",
            orderemail:"",
            comments:"",
            managertype:"",
            first_name:"",
            last_name:"",
            birthday:"",
            picture:"",
            gender:"",
            status:"",
            newpassword:"",
            _id : ""
        };
        this.engine = new RequestEngine();
    }



    componentDidMount() {
        const edit = this.props.location.data

        if (edit){
            this.setState({
                ...edit,
                managertype:edit.managertype && edit.managertype._id,
            });


        }

        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("managertype");
        if(responseuni){
            const data = responseuni.data.data;
            let managertypelist = []
            data.map(p =>
                managertypelist.push( { value: p._id, label: p.name })
            )

            this.setState({
                managertypelist: managertypelist
            });
        }
    }


    handleValidSubmit = () => {
        this.engine.saveUserManager(this.state,(response) => {
            console.log(response.status);
            if(response.status === 200){
                this.props.history.push('/admin/manager');
            }
            this.setState({
                btnloading: false
            })
        });

    };


    rendeVendor() {
        const {vendorid,orderemail} = this.state;
        return (<>
            <CardHeader>
                <CardTitle tag="h4">Vendor Details</CardTitle>
            </CardHeader>
            <CardBody>
           <FormRow label="Order email" type={"email"} name="orderemail" data={orderemail} changeInput={this.changeInput.bind(this)} />
            <FormRow label="Vendor ID" name="vendorid" data={vendorid} changeInput={this.changeInput.bind(this)} />
            </CardBody>
        </>)
    }


    render() {
        const {
            password,
            username,
            comments,
            phone,
            newpassword,
            managertype,
            btnloading,_id} = this.state;
        const {normaluser} = this.props
        const edit = this.props.location.data

        let isvendor = (managertype=="61bcfc9b43f5279d37685b0e")
        return (
            <>
                <div className="content english">

                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Label sm="2">Type</Label>
                                            <Col sm="7">
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="managertype"
                                                        isMulti ={false}
                                                        isSearchable ={false}
                                                        value={this.state.managertypelist.find(v => v.value === this.state.managertype)}
                                                        onChange={data =>
                                                            this.setState({ managertype: data.value })
                                                        }
                                                        options={this.state.managertypelist}
                                                        placeholder="Please select "
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <FormRow label="Full name" name="full_name" data={this.state.full_name} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Email" type={"email"} name="email" data={this.state.email} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Phone"  required={false}  name="phone" data={phone} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="username"  name="username" data={username} changeInput={this.changeInput.bind(this)} />
                                        {!edit && <FormRow label="Password " type="password" name="password" data={password} changeInput={this.changeInput.bind(this)} />}
                                        {!normaluser && (edit && <FormRow label="New Password " required={false} type="password" name="newpassword" data={newpassword} changeInput={this.changeInput.bind(this)} />)}
                                        <FormRow required={false} label="Comment" type="textarea" name="comments" data={comments} changeInput={this.changeInput.bind(this)} />
                                    </CardBody>
                                    {isvendor && this.rendeVendor()}
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


