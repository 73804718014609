import React       from 'react';

import {
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import { AvField,AvInput,AvGroup } from 'availity-reactstrap-validation';



function Checkboxrow({
                         label       = 'Label',
                         name ,
                         data       ,
                         changeInput,
                         labelyes='YES'

                     }) {


    return (
        <Row>
            <Label sm="2">{label}</Label>
            <Col sm="7">
                <FormGroup >
                    <Label check>
                        <AvInput style={{opacity:1,visibility:"visible",margin:0,position:"relative", top:2,   marginTop: 10}} type="checkbox" value = {data}  name={name} onChange={e =>
                            changeInput(e, name)
                        } />  &nbsp;{labelyes}
                    </Label>
                </FormGroup>

            </Col>



        </Row>
    );
}


export default Checkboxrow;
